<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.baseModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3>
            <i class="fi-checkbox" style="color: black"></i>&nbsp;&nbsp;&nbsp;&nbsp;Kinder-Anwesenheit
          </h3>
        </div>
      </div>
      <div v-if="facilitiesNotExists" class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Kinder-Anwesenheit"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell">
          <md-card id="absence-table-card-id" style="height: 700px;">
            <md-card-header style="padding: 1rem;">
              <div class="grid-x grid-padding-x" v-if="availableFacilities && availableFacilities.length > 0">
                <div class="cell large-3 medium-6 small-10">
                  <md-field>
                    <label>Einrichtung</label>
                    <md-select v-model="selectedFacilityReferenceNumber"
                               :disabled="sending || availableFacilities.length <= 1"
                               @md-selected="onSelectFacility">
                      <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                 :key="facility.referenceNumber">
                        {{ facility.longName }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-3 medium-6 small-10">
                  <md-field>
                    <label>Bereiche</label>
                    <md-select v-model="selectedDivisions" :disabled="sending" @md-selected="onSelectDivision"
                               multiple>
                      <md-option v-for="division of labels.divisionTypes" :value="division" :key="division">
                        {{labels.divisiontype[division]}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-3 medium-6 small-10">
                  <md-field>
                    <label>Gruppen</label>
                    <md-select v-model="selectedGroups" :disabled="sending" @md-selected="onSelectGroup"
                               multiple>
                      <md-option v-for="group of groups" :value="group.key" :key="group.id">
                        {{group.name}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-3 medium-6 small-10">
                  <md-field>
                    <label>Filter</label>
                    <md-select v-model="selectedFilter" :disabled="sending" @md-selected="onSelectFilter"
                               multiple>
                      <md-option v-for="filter of filters" :value="filter.id" :key="filter.id">
                        {{filter.name}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell" style="min-height: 12px;">
                  <md-progress-bar md-mode="indeterminate" v-if="sending"/>
                </div>
              </div>
            </md-card-header>
            <md-card-content style="padding: 0 1rem 1rem 1rem;">
              <md-card>
                <md-card-expand>
                  <md-card-actions md-alignment="space-between">
                    <md-card-header style="padding: 6px; width: 100%; height: 40px;">
                      <div class="grid-x">
                        <div class="cell large-4 medium-5 hide-for-small-only" style="margin-top: -4px;">
                          <div class="md-title" style="font-size: x-large; margin: 0;">
                            {{selectedFacilityName}}
                          </div>
                        </div>
                        <div class="cell large-5 medium-7 small-12 text-center">
                          <md-button class="md-icon-button md-dense md-primary" @click="onPreviousWeek" :disabled="sending">
                            <md-icon>chevron_left</md-icon>
                            <md-tooltip>vorherige Woche</md-tooltip>
                          </md-button>
                          <span class="md-title" style="font-size: large; color: cornflowerblue; min-width: 250px;display: inline-block;text-align: center;">
                            &nbsp;<span class="show-for-large">{{getMonthStr(selectedMonth)}}</span>
                            <span class="show-for-medium-only">{{getShortMonthStr(selectedMonth)}}</span>&nbsp;{{selectedYear}}&nbsp;-&nbsp;<b style="font-size: x-large;">{{getWeekStr()}}</b>&nbsp;
                          </span>
                          <md-button class="md-icon-button md-dense md-primary" @click="onNextWeek" :disabled="sending">
                            <md-icon>chevron_right</md-icon>
                            <md-tooltip>nächste Woche</md-tooltip>
                          </md-button>
                        </div>
                        <div class="cell large-1 hide-for-medium-only hide-for-small-only text-left">
                          <md-switch class="md-primary" v-model="showTodayOnly" style="margin-bottom: 0; margin-top: 6px;" :disabled="sending" @change="onToggleShowTodayOnly">
                            heute
                            <md-tooltip>Nur heute {{today | moment('DD.MM.YYYY')}} anzeigen</md-tooltip>
                          </md-switch>
                        </div>
                        <div class="cell large-2 hide-for-medium-only hide-for-small-only text-right">
                          <md-switch v-if="!showTodayOnly" class="md-primary" v-model="showAllDays" style="margin-bottom: 0; margin-top: 6px;" :disabled="sending">
                            Alle Tage
                            <md-tooltip>Alle Tage anzeigen</md-tooltip>
                          </md-switch>
                        </div>
                      </div>
                    </md-card-header>
                  </md-card-actions>
                </md-card-expand>
              </md-card>
              <div id="table-wrapper-id" class="table-wrapper">
                <table>
                  <thead>
                  <tr>
                    <th style="z-index: 2;">
                      <md-card style="height: 70px; min-width: 350px;">
                        <md-card-header>
                          <div class="md-layout">
                            <div class="md-layout-item md-size-70">
                              <div class="md-headline text-center" style="padding-top: 10px; color: cornflowerblue">
                                <i><b>{{filteredAbsenceOverview.length}}</b> Kind<span v-if="filteredAbsenceOverview.length !== 1">er</span></i>
                              </div>
                            </div>
                            <div v-if="!showTodayOnly" class="md-layout-item md-size-15 text-right">
                              <md-button class="md-icon-button" style="margin-top: 4px;" :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite" @click="onSetChildrenWeekAbsence(false)">
                                <md-icon style="color: darkseagreen;">done_all</md-icon>
                                <md-tooltip>Anwesenheit von <b>{{filteredAbsenceOverview.length}}</b> Kind<span v-if="filteredAbsenceOverview.length !== 1">ern</span> für gesamte Woche aktivieren.<br>(Diese Aktion muss vor Ausführung noch bestätigt werden.)</md-tooltip>
                              </md-button>
                            </div>
                            <div v-if="!showTodayOnly" class="md-layout-item md-size-15 text-right">
                              <md-button class="md-icon-button" style="margin-top: 4px;" :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite" @click="onSetChildrenWeekAbsence(true)">
                                <md-icon style="color: red;">close</md-icon>
                                <md-tooltip>Anwesenheit von <b>{{filteredAbsenceOverview.length}}</b> Kind<span v-if="filteredAbsenceOverview.length !== 1">ern</span> für gesamte Woche <b>de</b>aktivieren.<br>(Diese Aktion muss vor Ausführung noch bestätigt werden.)</md-tooltip>
                              </md-button>
                            </div>
                          </div>
                          <div class="md-layout">
                            <div class="md-layout-item md-size-15 text-center"></div>
                            <div class="md-layout-item md-size-30 text-center">
                              <md-button style="height: 20px;" @click="onToggleSortTableByLastName()">
                                <md-icon :style="getSortStateColor(sortByLastNameDesc)">expand_more</md-icon>
                                <md-icon :style="getSortStateColor(sortByLastNameAsc)">expand_less</md-icon>
                                <md-tooltip md-direction="top">Nach Nachname sortieren</md-tooltip>
                              </md-button>
                            </div>
                            <div class="md-layout-item md-size-10 text-center"></div>
                            <div class="md-layout-item md-size-30">
                              <md-button style="height: 20px;" @click="onToggleSortTableByFirstName()">
                                <md-icon :style="getSortStateColor(sortByFirstNameDesc)">expand_more</md-icon>
                                <md-icon :style="getSortStateColor(sortByFirstNameAsc)">expand_less</md-icon>
                                <md-tooltip md-direction="top">Nach Vorname sortieren</md-tooltip>
                              </md-button>
                            </div>
                            <div class="md-layout-item md-size-15 text-center"></div>
                          </div>
                        </md-card-header>
                      </md-card>
                    </th>
                    <th v-for="(day, index) of $material.locale.shortDays" style="z-index: 2;" :key="index"
                        v-if="selectedFacility && (isOpenDay(day) || showAllDays) && (!showTodayOnly || (showTodayOnly && day === weekShortDayToday))">
                      <md-card style="height: 70px;">
                        <div style="overflow: hidden">
                          <md-card-header class="text-center" style="margin-top: 10px;">
                            <div class="md-layout">
                              <div class="md-layout-item md-size-60">
                                <span class="md-title"><i><b v-if="isToday(index)" style="color: darkseagreen;">{{day}}</b><span v-else>{{day}}</span></i></span>&nbsp;
                                <span style="font-size: smaller;" class="hide-for-small-only hide-for-medium-only">
                                  <i><b v-if="isToday(index)" style="color: darkseagreen;">{{getDateStr(index)}}</b><span v-else>{{getDateStr(index)}}</span></i>
                                </span>
                                <md-tooltip>
                                  <span v-if="isToday(index)">heute</span>
                                  <span v-if="isToday(index + 1)">gestern</span>
                                  <span v-if="isToday(index - 1)">morgen</span>
                                </md-tooltip>
                              </div>
                              <div v-if="!showTodayOnly" class="md-layout-item md-size-20 text-right">
                                <md-button class="md-icon-button" style="margin-top: -6px;" :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite" @click="onSetChildrenDayAbsence(false, index)">
                                  <md-icon style="color: darkseagreen;">done_all</md-icon>
                                  <md-tooltip>Anwesenheit von <b>{{filteredAbsenceOverview.length}}</b> Kind<span v-if="filteredAbsenceOverview.length !== 1">ern</span> für <b>{{day}} {{getDateStr(index)}}</b> aktivieren.<br>(Diese Aktion muss vor Ausführung noch bestätigt werden.)</md-tooltip>
                                </md-button>
                              </div>
                              <div v-if="!showTodayOnly" class="md-layout-item md-size-20 text-right">
                                <md-button class="md-icon-button" style="margin-top: -6px;" :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite" @click="onSetChildrenDayAbsence(true, index)">
                                  <md-icon style="color: red;">close</md-icon>
                                  <md-tooltip>Anwesenheit von <b>{{filteredAbsenceOverview.length}}</b> Kind<span v-if="filteredAbsenceOverview.length !== 1">ern</span> für <b>{{day}} {{getDateStr(index)}}</b> <b>de</b>aktivieren.<br>(Diese Aktion muss vor Ausführung noch bestätigt werden.)</md-tooltip>
                                </md-button>
                              </div>
                            </div>
                            <div class="md-layout">
                              <div class="md-layout-item md-size-50">
                                <span style="color: darkseagreen; font-size: small;">{{getNumberOfPresentChildrenForDay(index)}}
                                  <md-tooltip><b>{{getNumberOfPresentChildrenForDay(index)}}</b> Kinder am <b>{{day}} {{getDateStr(index)}}</b> anwesend</md-tooltip>
                                </span>
                              </div>
                              <div class="md-layout-item md-size-50">
                                <span style="color: red; font-size: small;">{{getNumberOfAbsentChildrenForDay(index)}}
                                  <md-tooltip><b>{{getNumberOfAbsentChildrenForDay(index)}}</b> Kinder am <b>{{day}} {{getDateStr(index)}}</b> abwesend</md-tooltip>
                                </span>
                              </div>
                            </div>
                          </md-card-header>
                        </div>
                      </md-card>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(absence, row) of filteredAbsenceOverview" :key="absence.child.id + row">
                    <td>
                      <md-card class="md-primary children-column">
                        <md-card-header>
                          <div class="md-layout">
                            <div class="md-layout-item md-size-15 text-left" style="padding-top: 8px;">
                              <ImageView :imageId="absence.child.imageId" size="micro"></ImageView>
                            </div>
                            <div class="md-layout-item md-size-75 text-left">
                              <md-tooltip md-delay="500">
                                <ChildBriefRecord :child="absence.child" :groups="groups" :toolTipOnly="true"></ChildBriefRecord>
                              </md-tooltip>
                              <div style="font-size: smaller; padding-top: 12px;">
                                <router-link :to="'/Kinder/'+ absence.child.id">
                                  <span v-html="childRecordTitle(absence.child)"></span>
                                  <md-tooltip md-delay="2500" style="font-size: medium;">Daten öffnen für <b>{{absence.child.masterdata.fullName}}</b></md-tooltip>
                                </router-link>
                              </div>
                            </div>
                            <div v-if="!showTodayOnly" class="md-layout-item md-size-10">
                              <md-button v-if="weekDaysPresent(absence.child) < numberOpenDays(absence.child)" class="md-icon-button" style="margin-top: 4px;"
                                         :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite" @click="onSetChildAbsence(absence.child, false)">
                                <md-icon style="color: darkseagreen;">done_all</md-icon>
                                <md-tooltip>Anwesenheit von <b>{{absence.child.masterdata.fullName}}</b> für gesamte Woche aktivieren</md-tooltip>
                              </md-button>
                              <md-button v-else class="md-icon-button" style="margin-top: 4px;" :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite" @click="onSetChildAbsence(absence.child, true)">
                                <md-icon style="color: red;">close</md-icon>
                                <md-tooltip>Anwesenheit von <b>{{absence.child.masterdata.fullName}}</b> für gesamte Woche <b>de</b>aktivieren</md-tooltip>
                              </md-button>
                            </div>
                          </div>
                        </md-card-header>
                      </md-card>
                    </td>
                    <td v-for="(day, index) of $material.locale.shortDays" :key="index"
                        v-if="selectedFacility && (isOpenDay(day) || showAllDays)  && (!showTodayOnly || (showTodayOnly && day === weekShortDayToday))">
                      <md-card class="child-cell">
                        <md-card-content v-if="!sending" style="height: 50px;">
                          <div v-if="isOpenDay(day)" class="md-layout">
                            <div class="md-layout-item md-size-65 text-center hide-for-small-only hide-for-medium-only" style="height: 25px;">
                              <div v-if="isPresent(absence, index)" style="font-size: medium">anwesend</div>
                              <div v-else style="font-size: medium; color: darkgrey"><i>abwesend</i></div>
                              <md-tooltip md-delay="500">{{getAbsenceReason(absence, index)}}</md-tooltip>
                            </div>
                            <div class="md-layout-item md-size-35 text-right" style="height: 25px;">
                              <md-button class="md-icon-button" style="margin-top: -10px;" @click="onToggleChildAbsence(absence.child, getDateKey(index))"
                                         :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                                <md-icon v-if="isPresent(absence, index)" style="color: darkseagreen;">check</md-icon>
                                <md-icon v-else style="color: red;">close</md-icon>
                                <md-tooltip>Anwesenheit von <b>{{absence.child.masterdata.fullName}}</b> für <b>{{ getDateStr(index) }}</b> ändern</md-tooltip>
                              </md-button>
                            </div>
                          </div>
                          <div v-else class="cell text-center">
                            <div style="font-size: medium; color: lightgray"><i>geschlossen</i></div>
                          </div>
                        </md-card-content>
                      </md-card>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="BASE" moduleFunction="Kinder-Anwesenheit"></NoModuleCard>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showConfirmAbsenceCommandDialog">
      <div v-if="absenceCommand" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon>check</md-icon>&nbsp;&nbsp;&nbsp;Änderung der Anwesenheiten bestätigen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Sollen die Anwesenheiten von <b style="font-size: large; color: cornflowerblue;">{{absenceCommand.childIds.length}}</b> Kind<span v-if="absenceCommand.childIds.length !== 1">ern</span>
            <span v-if="absenceCommand.startDate !== absenceCommand.endDate"> für den Zeitraum vom <b style="font-size: medium; color: cornflowerblue;">{{absenceCommand.startDate | moment('DD.MM.YYYY')}}</b>
            bis <b style="font-size: medium; color: cornflowerblue;">{{absenceCommand.endDate | moment('DD.MM.YYYY')}}</b>
            </span>
            <span v-else>
              für den <b style="font-size: medium; color: cornflowerblue;">{{absenceCommand.startDate | moment('DD.MM.YYYY')}}</b>
            </span>
            <br> wirklich auf
            <span v-if="absenceCommand.absent" style="color: red; font-size: large;"><b>abwesend</b></span>
            <span v-else style="color: darkseagreen; font-size: large;"><b>anwesend</b></span> gesetzt werden?
          </md-content>
        </div>
        <div class="cell text-center">
          <div @click="onConfirmSetChildrenWeekAbsence" class="button success">
            <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Ja, Anwesenheiten jetzt ändern
          </div>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="showConfirmAbsenceCommandDialog = false;"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showConfirmAbsenceCommandDialog = false;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>

</template>

<script>
  import Spinner from 'vue-simple-spinner';
  import Vue from 'vue';
  import HttpErrorHandler from '@/services/HttpErrorHandler';
  import FacilityService from "@/services/FacilityService";
  import NoFacilityCard from "@/components/cards/NoFacilityCard";
  import NoModuleCard from '@/components/cards/NoModuleCard';
  import GroupService from "@/services/GroupService";
  import ImageView from "@/components/views/ImageView";
  import ChildService from "@/services/ChildService";
  import ChildBriefRecord from "@/components/fabs/ChildBriefRecord";
  import jQuery from "jquery";

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByDivision = (items, terms) => {
    if (terms) {
      return items.filter(item => {
        for (let term of terms) {
          if (toLower(item.child.divisiontype).includes(toLower(term))) {
            return true;
          }
        }
        return false;
      });
    }

    return items;
  };

  const searchByGroup = (items, terms) => {
    if (terms) {
      if (terms.length <= 0) {
        return items;
      }

      return items.filter(item => {
        if (item.child.groupReferences) {
          for (let groupReference of item.child.groupReferences) {
            for (let term of terms) {
              if (toLower(groupReference).includes(toLower(term))) {
                return true;
              }
            }
          }
          return false;
        }
        return false;
      });
    }

    return items;
  };

  const filterChildren = (items, selectedFilter) => {
    return items.filter(item => {
      if (selectedFilter.includes('PAST') && item.child.filterState.includes('PAST')) {
        return true;
      } else if (selectedFilter.includes('CURRENT') && item.child.filterState.includes('CURRENT')) {
        return true;
      } else if (selectedFilter.includes('FUTURE') && item.child.filterState.includes('FUTURE')) {
        return true;
      } else if (selectedFilter.includes('FUTURE_EXIT') && item.child.filterState.includes('FUTURE_EXIT')) {
        return true;
      } else if (selectedFilter.includes('FUTURE_EXIT_CURRENT') && item.child.filterState.includes('FUTURE_EXIT_CURRENT')) {
        return true;
      } else {
        return (selectedFilter.length <= 0);
      }
    });
  };

  export default {
    name: 'ChildrenPresence',

    components: {
      NoFacilityCard,
      NoModuleCard,
      ImageView,
      ChildBriefRecord,
      'vue-simple-spinner': Spinner,
    },

    mounted() {
      this.reloadAbsences();
      window.addEventListener('resize', (event) => {
        this.resizeDialogElements(event);
      }, { passive: true });

      HttpErrorHandler.maintainDarkMode(this);
    },

    data() {
      return {
        sending: false,

        startDate: Vue.moment().add(-Vue.moment().weekday(),'days').format('DD.MM.YYYY'),
        endDate: Vue.moment().add(-Vue.moment().weekday() + 6,'days').format('DD.MM.YYYY'),
        absenceOverview: [],
        filteredAbsenceOverview: [],

        groups: [],
        availableFacilities: [],
        allowedFacilities: [],
        selectedFacilityReferenceNumber: null,
        selectedFacilityName: '',
        selectedFacility: null,

        selectedDivisions: [],
        selectedGroups:[],
        selectedGroupIds: [],
        selectedFilter: [],

        selectedYear: Vue.moment().format("YYYY"),
        selectedMonth: Vue.moment().format("M"),
        today: Vue.moment(),
        selectedDate: Vue.moment(),
        weekShortDayToday: this.$material.locale.shortDays[Vue.moment().weekday()], // Vue.moment().format('LLLL').substr(0, 2),

        showAllDays: false,
        showTodayOnly: false,

        showConfirmAbsenceCommandDialog: false,
        absenceCommand: null,

        sortByFirstNameAsc: false,
        sortByFirstNameDesc: false,
        sortByLastNameAsc: true,
        sortByLastNameDesc: false,
      }
    },

    methods: {

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        if (this.selectedFacilityReferenceNumber === '*') {
          this.selectedFacilityReferenceNumber = null;
        }

        if (localStorage.getItem(this.user.md5 + '@selectedDivisions')) {
          this.selectedDivisions = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedDivisions'));
        }

        this.selectedGroups = [];
        if (localStorage.getItem(this.user.md5 + '@selectedGroups')) {
          this.selectedGroups = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedGroups'));
        }

        this.selectedFilter = ['CURRENT', 'FUTURE'];
        if (localStorage.getItem(this.user.md5 + '@selectedFilterChildren')) {
          this.selectedFilter = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedFilterChildren'));
        }
      },

      initData() {
        if (this.labels && this.labels.divisiontype && this.selectedDivisions && this.selectedDivisions.length <= 0) {
          this.selectedDivisions = [];
          Object.keys(this.labels.divisiontype).forEach(key => {
            this.selectedDivisions.push(key);
          });
        }
      },

      resizeDialogElements() {
        setTimeout(() => jQuery('#absence-table-card-id').height(jQuery('.content').height() - 260), 10);
        setTimeout(() => jQuery('#table-wrapper-id').height(jQuery('.content').height() - 480), 20);
      },

      getMonthStr(month) {
        return Vue.moment(month + '-01-' + this.selectedYear, 'MM-DD-YYYY').format('MMMM');
      },

      getShortMonthStr(month) {
        return Vue.moment(month + '-01-' + this.selectedYear, 'MM-DD-YYYY').format('MMM');
      },

      getWeekStr() {
        return 'KW ' + Vue.moment(this.selectedDate).week();
      },

      getDateStr(weekDayIndex) {
        let index = weekDayIndex - Vue.moment().weekday();
        return Vue.moment(this.selectedDate).add('days', index).format('DD. MMM');
      },

      isToday(weekDayIndex) {
        let index = weekDayIndex - Vue.moment().weekday();
        let today = Vue.moment().format('DD.MM.YYYY');
        let checkDay = Vue.moment(this.selectedDate).add('days', index).format('DD.MM.YYYY');
        return checkDay === today;
      },

      isOpenDay(day) {
        return this.selectedFacility.kibigData.openingDays.includes(day.toUpperCase().substr(0, 2));
      },

      isPresent(absence, weekDayIndex) {
        return absence.absenceMap[this.getDateKey(weekDayIndex)] ? absence.absenceMap[this.getDateKey(weekDayIndex)].toString().startsWith('HERE') : false;
      },

      getAbsenceReason(absence, weekDayIndex) {
        return this.labels.absenceStatus[absence.absenceMap[this.getDateKey(weekDayIndex)].toString()];
      },

      getNumberOfAbsentChildrenForDay(weekDayIndex) {
        let absenceCount = 0;
        this.filteredAbsenceOverview.forEach(absence => {
          if (!this.isPresent(absence, weekDayIndex)) {
            absenceCount++;
          }
        })
        return absenceCount;
      },

      getNumberOfPresentChildrenForDay(weekDayIndex) {
        return this.filteredAbsenceOverview.length - this.getNumberOfAbsentChildrenForDay(weekDayIndex);
      },

      getDateKey(weekDayIndex) {
        let index = weekDayIndex - Vue.moment().weekday();
        return Vue.moment(this.selectedDate).add('days', index).format('YYYY-MM-DD');
      },

      childRecordTitle(child) {
        let title = '';

        if (child) {
          if (child.masterdata.sex === 'MALE') {
            title += '<span class="MALE">&#9794;';
          } else if (child.masterdata.sex === 'FEMALE') {
            title += '<span class="FEMALE">&#9792;';
          } else if (child.masterdata.sex === 'DIVERSE') {
            title += '<span class="DIVERSE">&#9954;';
          }
          title += '</span>&nbsp;';

          title += '<span style="font-size: large;">' + child.masterdata.lastname + ' ' + child.masterdata.firstname + '</span>';
        }

        return title;
      },

      onPreviousWeek() {
        this.showTodayOnly = false;
        this.selectedDate = Vue.moment(this.selectedDate).add('weeks', -1);
        this.updateSelectedMonthAndYear();

        this.startDate = Vue.moment(this.startDate, 'DD.MM.YYYY').add('-1','weeks').format('DD.MM.YYYY');
        this.endDate = Vue.moment(this.endDate, 'DD.MM.YYYY').add('-1','weeks').format('DD.MM.YYYY');
        this.getAbsenceOverview(this.selectedFacilityReferenceNumber, this.startDate, this.endDate);
      },

      onNextWeek() {
        this.showTodayOnly = false;
        this.selectedDate = Vue.moment(this.selectedDate).add('weeks', 1);
        this.updateSelectedMonthAndYear();

        this.startDate = Vue.moment(this.startDate, 'DD.MM.YYYY').add('1','weeks').format('DD.MM.YYYY');
        this.endDate = Vue.moment(this.endDate, 'DD.MM.YYYY').add('1','weeks').format('DD.MM.YYYY');
        this.getAbsenceOverview(this.selectedFacilityReferenceNumber, this.startDate, this.endDate);
      },

      onToggleShowTodayOnly() {
        this.selectedDate = this.today;
        this.startDate = Vue.moment().add(-Vue.moment().weekday(),'days').format('DD.MM.YYYY');
        this.endDate = Vue.moment().add(-Vue.moment().weekday() + 6,'days').format('DD.MM.YYYY');
        this.getAbsenceOverview(this.selectedFacilityReferenceNumber, this.startDate, this.endDate);
      },

      updateSelectedMonthAndYear() {
        this.selectedMonth = Vue.moment(this.selectedDate).format("M");
        this.selectedYear = Vue.moment(this.selectedDate).format("YYYY");
      },

      buildAvailableFacilities() {
        this.availableFacilities = this.getAvailableFacilities;
        for (let i = 0; i < this.availableFacilities.length; i++) {
          if (this.availableFacilities[i].referenceNumber === '*') {
            this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
          }
        }
        if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
          this.selectedFacilityName = this.availableFacilities[0].name;
          this.selectedFacility = this.availableFacilities[0];
        }
        let i = 0;
        for (let facility of this.availableFacilities) {
          if (i > 0) {
            this.allowedFacilities.push(facility);
          }
          i++;
        }
        this.onSelectFacility();
        this.initData();
      },

      reloadAbsences() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            this.restoreUserSettings();

            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }

            this.buildAvailableFacilities();

            clearInterval(reloadIntervalId);

          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      getAbsenceOverview(referenceNumber, startDate, endDate) {
        this.sending = true;
        let start = Vue.moment(startDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        let end = Vue.moment(endDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        FacilityService.getAbsenceOverview(referenceNumber, start, end)
          .then(response => {
            this.absenceOverview = response.data;
            this.sending = false;
            this.filterTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Anwesenheiten für Einrichtung mit Nummer <b>' + referenceNumber + '</b>');
            this.sending = false;
          })
      },

      getAllFacilityGroups(facilityReferenceNumber) {
        this.sending = true;
        GroupService.getAllFacilityGroups(facilityReferenceNumber)
          .then(response => {
            this.groups = response.data;
            this.sending = false;
            this.determineSelectedGroupIds();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Gruppen für Träger ' + this.organization.name);
            this.sending = false;
          })
      },

      onToggleSortTableByFirstName() {
        this.sortByFirstNameAsc = !this.sortByFirstNameAsc;
        this.sortByFirstNameDesc = !this.sortByFirstNameAsc;

        this.sortByLastNameAsc = false;
        this.sortByLastNameDesc = false;

        this.filteredAbsenceOverview = this.sortTable(this.filteredAbsenceOverview);
      },

      onToggleSortTableByLastName() {
        this.sortByLastNameAsc = !this.sortByLastNameAsc;
        this.sortByLastNameDesc = !this.sortByLastNameAsc;

        this.sortByFirstNameAsc = false;
        this.sortByFirstNameDesc = false;

        this.filteredAbsenceOverview = this.sortTable(this.filteredAbsenceOverview);
      },

      getSortStateColor(state) {
        return state ? 'color: cornflowerblue' : '';
      },

      sortTable(filteredAbsenceOverview) {
        let childNames = [];
        filteredAbsenceOverview.forEach(absence => {
          if (this.sortByFirstNameAsc || this.sortByFirstNameDesc) {
            childNames.push(absence.child.masterdata.firstname + absence.child.masterdata.lastname);
          } else {
            childNames.push(absence.child.masterdata.lastname + absence.child.masterdata.firstname);
          }
        })

        childNames.sort();
        if (this.sortByFirstNameDesc || this.sortByLastNameDesc) {
          childNames.reverse();
        }

        let sortedAbsenceOverview = [];
        childNames.forEach(name => {
          for (let absence of filteredAbsenceOverview) {
            if (this.sortByFirstNameAsc || this.sortByFirstNameDesc) {
              if (absence.child.masterdata.firstname + absence.child.masterdata.lastname === name) {
                sortedAbsenceOverview.push(absence);
                break;
              }
            } else {
              if (absence.child.masterdata.lastname + absence.child.masterdata.firstname === name) {
                sortedAbsenceOverview.push(absence);
                break;
              }
            }
          }
        })

        return sortedAbsenceOverview;
      },

      filterTable() {
        this.filteredAbsenceOverview = searchByDivision(this.absenceOverview, this.selectedDivisions);
        this.filteredAbsenceOverview = searchByGroup(this.filteredAbsenceOverview, this.selectedGroupIds);
        this.filteredAbsenceOverview = filterChildren(this.filteredAbsenceOverview, this.selectedFilter);
        this.filteredAbsenceOverview = this.sortTable(this.filteredAbsenceOverview);
      },

      onSelectFacility() {
        if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

          for (let fac of this.availableFacilities) {
            if (fac.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = fac.name;
              this.selectedFacility = fac;
            }
          }

          this.selectedGroups = [];
          this.selectedGroupIds = [];
          this.getAllFacilityGroups(this.selectedFacilityReferenceNumber);
          this.getAbsenceOverview(this.selectedFacilityReferenceNumber, this.startDate, this.endDate);
        }
      },

      onSelectDivision() {
        localStorage.setItem(this.user.md5 + '@selectedDivisions', JSON.stringify(this.selectedDivisions));
        this.filterTable();
      },

      determineSelectedGroupIds() {
        this.selectedGroupIds = [];
        for (const key of this.selectedGroups) {
          for (const group of this.groups) {
            if (group.key === key) {
              this.selectedGroupIds.push(group.id);
            }
          }
        }
      },

      onSelectGroup() {
        localStorage.setItem(this.user.md5 + '@selectedGroups', JSON.stringify(this.selectedGroups));
        this.determineSelectedGroupIds();
        this.filterTable();
      },

      onSelectFilter() {
        localStorage.setItem(this.user.md5 + '@selectedFilterChildren', JSON.stringify(this.selectedFilter));
        this.filterTable();
      },

      weekDaysPresent(child) {
        let count = 0;
        this.absenceOverview.forEach(absence => {
          if (absence.child.id === child.id) {
            for (let i = 0; i < 7; i++) {
              let dayDate = Vue.moment(this.startDate, 'DD.MM.YYYY').add(i, 'days');
              if (absence.absenceMap[dayDate.format('YYYY-MM-DD')] && absence.absenceMap[dayDate.format('YYYY-MM-DD')].toString().startsWith('HERE')) {
                count++;
              }
            }
          }
        })
        return count;
      },

      numberOpenDays() {
        let num = 0;
        for (let day of this.$material.locale.shortDays) {
          if (this.selectedFacility.kibigData.openingDays.includes(day.toUpperCase().substr(0, 2))) {
            num++;
          }
        }
        return num;
      },

      onSetChildrenDayAbsence(absent, weekDayIndex) {
        let childIds = [];
        this.filteredAbsenceOverview.forEach(absence => {
          childIds.push(absence.child.id);
        })

        let index = weekDayIndex - Vue.moment().weekday();
        let date = Vue.moment(this.selectedDate).add('days', index).format('YYYY-MM-DD');

        this.absenceCommand = {
          childIds: childIds,
          startDate: date,
          endDate: date,
          absent: absent,
        }

        this.showConfirmAbsenceCommandDialog = true;
      },

      onSetChildrenWeekAbsence(absent) {
        let childIds = [];
        this.filteredAbsenceOverview.forEach(absence => {
          childIds.push(absence.child.id);
        })
        this.absenceCommand = {
          childIds: childIds,
          startDate: Vue.moment(this.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          endDate: Vue.moment(this.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          absent: absent,
        }

        this.showConfirmAbsenceCommandDialog = true;
      },

      onConfirmSetChildrenWeekAbsence() {
        this.showConfirmAbsenceCommandDialog = false;
        this.setChildAbsences(this.absenceCommand);
      },

      onSetChildAbsence(child, absent) {
        let childIds = [];
        this.filteredAbsenceOverview.forEach(absence => {
          if (absence.child.id === child.id) {
            childIds.push(child.id);
          }
        })

        let absenceCommand = {
          childIds: childIds,
          startDate: Vue.moment(this.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          endDate: Vue.moment(this.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          absent: absent,
        }

        this.setChildAbsences(absenceCommand);
      },

      onToggleChildAbsence(child, date) {
        this.toggleChildAbsence(child, date);
      },

      toggleChildAbsence(child, date) {
        ChildService.toggleChildAbsence(child.id, date)
          .then((response) => {
            this.absenceOverview.forEach(absence => {
              if (absence.child.id === child.id) {
                absence.absenceMap[date] = response.data.absenceStatusToday;
              }
            })
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Anwesenheit von Kind <b>' + child.masterdata.fullName + '</b>');
          })
      },

      setChildAbsences(absenceCommand) {
        this.sending = true;
        ChildService.setChildAbsences(absenceCommand)
          .then((response) => {
            let absenceOverviewNew = response.data;
            this.absenceOverview.forEach(absence => {
              absenceOverviewNew.forEach(absenceNew => {
                if (absence.child.id === absenceNew.child.id) {
                  Object.entries(absenceNew.absenceMap).forEach(entry => absence.absenceMap[entry[0]] = entry[1]);
                }
              })
            })
            this.sending = false;
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Setzen des Anwesenheiten aller Kinder für Woche von <b>' + this.startDate + '</b> bis <b>' + this.endDate + '</b>');
          })
      },
    },

    computed: {
      facilitiesNotExists() {
        return this.organization && this.organization.facilities && this.organization.facilities.length <= 0;
      },

      dataAvailable() {
        return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
            this.user.allowedFacilities.length > 0 && this.labels &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isFacility() {
        return this.$store.getters.facility
      },

      isDocumentation() {
        return this.$store.getters.documentation
      },

      isManagement() {
        return this.$store.getters.management
      },

      user() {
        return this.$store.getters.user;
      },

      organization() {
        return this.$store.getters.organization;
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },

      labels() {
        return this.$store.getters.labels
      },

      filters() {
        return this.$store.getters.filters
      },

      isDevEnv() {
        return process.env.NODE_ENV === 'development';
      },
    }
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
  }

  .color-span {
    color: cornflowerblue;
  }

  table {
    border-collapse: separate;
    empty-cells: hide;
    width: 100% !important;
    margin: 0;
    border-spacing: 1px;
  }

  th {
    color: grey;
    padding: 0;
    font-size: large;
    font-weight: lighter;
    width: 12.5%;
  }

  hr {
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .md-card-content {
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .md-card-header {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
  }

  .md-theme-default .md-card-actions {
    background-color: #f5f5f5 !important;
  }

  .md-theme-dark .md-card-actions {
    background-color: #525252 !important;
  }

  .md-card .md-title {
    font-size: 18px;
    font-weight: 100;
  }

  div.md-title {
    margin-top: 5px !important;
  }

  .md-card .md-subhead {
    font-size: 12px;
    text-align: center;
    position: relative;
    margin-top: -17px;
  }

  .md-card-load {
    width: 33%;
    display: inline-block;
  }

  .md-input {
    margin: 0;
  }

  .md-card-total-load {
    width: 80%;
    display: inline-block;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 500px;
    white-space: pre-wrap;
  }

  .warning {
    color: orangered;
  }

  @import "../../node_modules/vue-material/src/theme/engine";

  .md-tooltip {
    @include md-theme-component() {
      @include md-theme-property(color, text-primary, tooltip);
      @include md-theme-property(background-color, tooltip, background, .9);
    }
  }

  .md-card.md-theme-default.md-primary {
    background-color: white;
  }

  .md-card.md-theme-dark.md-primary {
    background-color: #424242;
  }

  .table-wrapper {
    border-style: solid;
    border-width: 1px;
    border-color: lightgray !important;
    overflow-y: scroll;
    height: 480px;
    border-spacing: 0;
  }

  .table-wrapper th {
    position: sticky;
    border-spacing: 2px;
    top: 0;
    padding: 1px 1px 0 0;
  }

  .table-wrapper td {
    padding: 1px 1px 0 0;
    margin: 0;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  a:hover {
    transition: color 0.25s ease-in-out;
    color: cornflowerblue !important;
    text-decoration: none;
  }

  .children-column {
    height: 50px; min-width: 350px;
  }

  .child-cell {
    height: 50px;
  }

  .md-card.md-theme-default.md-primary {
    background-color: white;
  }

  .md-card.md-theme-dark.md-primary {
    background-color: #424242;
  }

  .md-theme-default th .md-card {
    background: floralwhite;
  }

  .md-theme-dark th .md-card {
    background: #666666;
  }

  tbody tr:nth-child(even) td .md-card {
    background: whitesmoke !important;
  }

  .md-theme-dark tbody tr:nth-child(even) td .md-card {
    background: #323232 !important;
  }

</style>
